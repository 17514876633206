


























import Vue from 'vue';
import SystemParameter from '@/models/SystemParameter';
import SystemParameterService from '@/services/SystemParameterService';
import ParameterType from '@/models/ParameterType';
import ParameterEditor from '@/components/ParameterEditor.vue';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });
export default Vue.extend({
  name: 'stations',
  components: { ParameterEditor },
  data() {
    return {
      ParameterType,
      LoadState,
      windowPartLoadState: LoadState.NotStarted,
      parameters: undefined as SystemParameter[] | undefined
    };
  },
  async mounted() {
    this.parameters = undefined;
    this.parameters = await SystemParameterService.loadSystemParameters();
  },
  methods: {
    async onParameterChanged(parameterDefinitionId: string, newValue: string) {
      SystemParameterService.updateParameter(parameterDefinitionId, newValue);
    }
  }
});
